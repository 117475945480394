import React, { FC, useCallback, useContext, useState } from 'react';

import {
	AuthContext,
	FetchHelper,
	FetchResponse,
	LiveRegion,
	LoadingLottie,
	LoadingStatus,
	Stack,
} from '@icasdigital/icas.core.reactcomponents';

import { DropdownMenu } from '../components/DropdownMenu';
import { ErrorForm } from '../components/ErrorForm';
import { FileUpload } from '../components/FileUpload';
import { PostSubmissionPage } from '../components/PostSubmissionPage';
import { StudentDetails } from '../components/StudentDetails';
import SubjectsModuleRules from '../components/SubjectModuleRules';
import { SubjectSelection } from '../components/SubjectSelection';
import { Submission } from '../components/Submission';
import { useFetchData } from '../hooks/useFetchData';
import { SubjectsForSubmission, UniSubject } from '../types/UniInfo';
import { isUnauthCode, pageAndUserLoaded } from '../utils/responseCodes';

import './ContainerStyle.css';

type PBSubmissionBody = {
	StudentId: string;
	SubjectAcronymList: string[];
	Transcript: string;
	FileName: string;
	IsUni: boolean;
};

type UniSubmissionBody = PBSubmissionBody & {
	University: number;
	SubjectAcronymList: string[];
};

export const ExemptionsContainer: FC = () => {
	//#region State and Context Management
	const [isAccreditedUni, setIsAccreditedUni] = useState<boolean>();
	const [subjectsSelected, setSubjectsSelected] = useState<
		SubjectsForSubmission[]
	>([]);
	const [transcripts, setTranscripts] = useState<File[]>([]);
	const [isNotICAEW, setisNotICAEW] = useState<boolean>(true);

	//form status state
	const [loadingStatus, setLoadingStatus] = useState(
		LoadingStatus.IsNotLoading
	);

	const [submitted, setSubmitted] = useState<boolean>(false);
	const [successfulSubmission, setSuccessfulSubmission] =
		useState<boolean>(false);
	const [invalidOnSubmission, setInvalidOnSubmission] =
		useState<boolean>(false);

	const { userId, handleUnauth } = useContext(AuthContext);

	const pageLoaded = pageAndUserLoaded(userId, loadingStatus);

	const {
		studentInfo,
		universityInfo,
		handleSelectDegree,
		selectedDegree,
		degreeSelectionOutstanding,
		needsDegreeInfo,
	} = useFetchData({
		isNotICAEW,
		setIsAccreditedUni,
		setLoadingStatus,
		pageLoaded,
	});

	const validToSubmit: boolean =
		transcripts.length > 0 &&
		subjectsSelected.every(subject => subject.confirmed);

	//#endregion

	const handleSubmissionResponse = useCallback(
		(fetchResponse: FetchResponse): void => {
			setSubmitted(true);
			if (fetchResponse.ok) {
				setSuccessfulSubmission(true);
				return setLoadingStatus(LoadingStatus.LoadedSuccessfully);
			}
			if (isUnauthCode(fetchResponse.status)) {
				setLoadingStatus(LoadingStatus.EndedWithError);
				handleUnauth();
				return setSuccessfulSubmission(false);
			}

			setLoadingStatus(LoadingStatus.EndedWithError);
			setSuccessfulSubmission(false);
		},
		[handleUnauth]
	);

	const handleSubjectSelect = (id: string, checked: boolean): void => {
		setInvalidOnSubmission(false);
		const subject: SubjectsForSubmission[] = [
			{ subject: id, confirmed: false },
		];
		if (checked) {
			setSubjectsSelected([...subjectsSelected, ...subject]);
		} else {
			const subjects: SubjectsForSubmission[] = [...subjectsSelected];
			const filteredSubjects: SubjectsForSubmission[] = subjects.filter(
				s => s.subject !== subject[0].subject
			);
			setSubjectsSelected(filteredSubjects);
		}
	};

	const handleSubjectConfirmation = (
		subjectAcronym: string,
		confirmed: boolean
	) => {
		setInvalidOnSubmission(false);
		const subjects: SubjectsForSubmission[] = [...subjectsSelected];
		subjects.forEach(subject => {
			if (subject.subject === subjectAcronym) {
				subject.confirmed = confirmed;
			}
		});
		setSubjectsSelected(subjects);
	};

	const toBase64 = () =>
		new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(transcripts[0]);
			reader.onload = () => resolve(reader.result);
			reader.onerror = error => reject(error);
		});

	const handleSubmission = async (e: React.SyntheticEvent) => {
		e.preventDefault();
		setInvalidOnSubmission(!validToSubmit);
		if (validToSubmit) {
			setLoadingStatus(LoadingStatus.IsLoading);
			const subjects: string[] = [];
			subjectsSelected.forEach(subject => {
				subjects.push(subject.subject);
			});
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const transcript: any = await toBase64();
			//slice off the return string of document type and base64 from the document body otherwise dynamics cannot decode it
			const index = transcript.indexOf('base64,');
			const slicedTranscript = transcript.slice(index + 7);
			if (isNotICAEW) {
				universitySubmission(slicedTranscript, subjects);
			} else {
				pbSubmission(slicedTranscript, subjects);
			}
		} else {
			return;
		}
	};

	const universitySubmission = async (
		slicedTranscript: string,
		subjects: string[]
	) => {
		const submission: UniSubmissionBody = {
			StudentId: userId,
			University: studentInfo?.icas_universityattendedcode || 0,
			SubjectAcronymList: subjects,
			Transcript: slicedTranscript,
			FileName: transcripts[0].name,
			IsUni: true,
		};
		const submissionResponse = await FetchHelper.post(
			'/exemptions/submission',
			handleUnauth,
			submission as unknown as BodyInit
		);
		handleSubmissionResponse(submissionResponse);
	};

	//professional body submission e.g. ICAEW
	const pbSubmission = async (slicedTranscript: string, subjects: string[]) => {
		const submission: PBSubmissionBody = {
			StudentId: userId,
			SubjectAcronymList: subjects,
			Transcript: slicedTranscript,
			FileName: transcripts[0].name,
			IsUni: false,
		};
		const submissionResponse = await FetchHelper.post(
			'/exemptions/submission',
			handleUnauth,
			submission as unknown as BodyInit
		);
		handleSubmissionResponse(submissionResponse);
	};

	const renderRulesComponents: JSX.Element[] = subjectsSelected.map(subject => {
		const subjectAcronym: string = subject.subject;
		const subjectModuleInfo: UniSubject | undefined =
			universityInfo?.subjects?.find(
				uniSubject => uniSubject.name === subjectAcronym
			) ?? undefined;

		return (
			<SubjectsModuleRules
				key={subjectModuleInfo?.name}
				subject={subjectModuleInfo}
				handleConfirmed={handleSubjectConfirmation}
			/>
		);
	}) || <></>;

	const uploadFileHandler = <T,>(files: T[]) => {
		setInvalidOnSubmission(false);
		setTranscripts(files as File[]);
	};

	const removeFileHandler = () => {
		const noTranscript: File[] = [];
		setTranscripts(noTranscript);
	};

	const handleErrorClick = () => {
		setLoadingStatus(LoadingStatus.IsNotLoading);
		setSubmitted(false);
	};

	const handleICAEW = () => {
		setisNotICAEW(false);
	};

	if (loadingStatus === LoadingStatus.IsLoading) {
		return (
			<LoadingLottie
				style={{
					height: '15em',
					margin: 'auto',
					marginTop: '5em',
					marginBottom: '5em',
				}}
			/>
		);
	}
	if (submitted) {
		return <PostSubmissionPage success={successfulSubmission} />;
	}

	const firmName = studentInfo?.icas_Student_TrainingFirmid?.name || '';

	if (
		studentInfo &&
		loadingStatus === LoadingStatus.LoadedSuccessfully &&
		isAccreditedUni !== undefined
	) {
		return (
			<Stack as="div" gap={1.5}>
				<StudentDetails
					accreditedUni={isAccreditedUni ?? false}
					uniInfo={universityInfo}
					firmName={firmName}
					handleICAEW={handleICAEW}
					isNotICAEW={isNotICAEW}
					needsDegreeInfo={needsDegreeInfo}
					uniCode={studentInfo.icas_universityattendedcode}
				/>
				{degreeSelectionOutstanding && (
					<DropdownMenu
						uniCode={studentInfo.icas_universityattendedcode}
						handleSelect={handleSelectDegree}
					/>
				)}
				{(isAccreditedUni || !isNotICAEW) && !degreeSelectionOutstanding && (
					<Stack
						gap={1.5}
						as="form"
						onSubmit={handleSubmission}
						aria-describedby="form-error"
					>
						<SubjectSelection
							uniInfo={universityInfo}
							handleSelect={handleSubjectSelect}
							selectedSubjects={subjectsSelected}
						/>
						<LiveRegion id="rules" role="log">
							<Stack gap={1.5}>{renderRulesComponents}</Stack>
						</LiveRegion>
						<FileUpload
							upload={uploadFileHandler}
							remove={removeFileHandler}
							transcripts={transcripts}
						/>
						<Submission
							subjects={subjectsSelected}
							invalid={invalidOnSubmission}
						/>
					</Stack>
				)}
			</Stack>
		);
	}
	if (loadingStatus === LoadingStatus.EndedWithError) {
		return <ErrorForm pageType="page" handleClick={handleErrorClick} />;
	}
	return (
		<LoadingLottie
			style={{
				height: '15em',
				margin: 'auto',
				marginTop: '5em',
				marginBottom: '5em',
			}}
		/>
	);
};
