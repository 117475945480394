import React from 'react';

export const TopTab = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		viewBox="0 0 500 300"
		height={150}
		width={400}
		style={{
			display: 'block',
			top: '-30px',
			left: '-100px',
			margin: 'auto',
			position: 'absolute',
		}}
	>
		<defs>
			<clipPath id="clip-top-tab">
				<rect width="200" height="190" />
			</clipPath>
		</defs>
		<g id="top-tab" clipPath="clip-top-tab">
			<rect fill="rgba(255,255,255,0)" />
			<g id="surface1">
				<path
					fill="#22b9da"
					style={{ stroke: 'none', fillRule: 'nonzero', fillOpacity: 1 }}
					d="M 198.132812 51.457031 C 191.855469 48.867188 154.867188 48.328125 124.953125 48.328125 C 97.230469 48.328125 63.730469 48.90625 55.683594 50.179688 L 53.535156 50.519531 L 52.546875 51.902344 C 48.652344 57.371094 50.230469 67.160156 51.757812 76.621094 C 52.601562 80.882812 53.070312 85.175781 53.164062 89.472656 L 48.613281 119.757812 C 48.164062 120.554688 47.628906 121.328125 47.007812 122.066406 C 44.789062 124.882812 43.285156 127.738281 49.234375 131.714844 L 49.492188 131.882812 L 49.78125 132.019531 C 51.347656 132.835938 53.238281 133.292969 55.191406 133.324219 C 57.472656 133.308594 59.644531 132.632812 61.183594 131.449219 C 69.632812 125.351562 70.3125 101 68.730469 66.3125 C 68.644531 64.441406 68.570312 62.769531 68.519531 61.464844 C 83.832031 59.667969 99.332031 58.78125 114.859375 58.820312 C 122.414062 58.820312 129.429688 58.992188 136.21875 59.15625 C 142.488281 59.3125 148.40625 59.457031 154.296875 59.457031 C 157.902344 59.457031 161.164062 59.402344 164.273438 59.289062 C 167.246094 59.179688 169.957031 59.144531 172.4375 59.144531 C 175.714844 59.144531 178.589844 59.207031 181.101562 59.265625 C 183.144531 59.3125 184.960938 59.355469 186.570312 59.355469 C 191.015625 59.355469 196.035156 59.121094 199.339844 56.332031 L 202.84375 53.386719 Z M 198.132812 51.457031 "
				/>
			</g>
		</g>
	</svg>
);

export const BottomTab = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		viewBox="0 0 500 300"
		height={150}
		width={400}
		style={{
			display: 'block',
			bottom: '0px',
			right: '-100px',
			margin: 'auto',
			position: 'absolute',
		}}
	>
		<defs>
			<clipPath id="clip-bottom-tab">
				<rect width="500" height="350" />
			</clipPath>
		</defs>
		<g id="bottom-tab" clipPath="url(#clip-bottom-tab)">
			<rect fill="rgba(255,255,255,0)" />
			<g id="surface1">
				<path
					fill="#22b9da"
					style={{ stroke: 'none', fillRule: 'nonzero', fillOpacity: 1 }}
					d="M 450.617188 214.320312 L 450.359375 214.148438 L 450.070312 214.011719 C 448.460938 213.167969 446.511719 212.699219 444.503906 212.667969 C 442.160156 212.683594 439.929688 213.378906 438.351562 214.589844 C 429.480469 221.003906 428.78125 246.71875 430.445312 283.359375 C 430.539062 285.421875 430.625 287.253906 430.675781 288.65625 C 414.382812 290.585938 397.886719 291.53125 381.367188 291.488281 C 373.359375 291.488281 365.9375 291.308594 358.753906 291.132812 C 352.113281 290.96875 345.863281 290.8125 339.632812 290.8125 C 335.824219 290.8125 332.382812 290.871094 329.097656 290.992188 C 326.242188 291.097656 323.414062 291.148438 320.457031 291.148438 C 316.976562 291.148438 313.933594 291.078125 311.277344 291.011719 C 309.117188 290.960938 307.199219 290.917969 305.496094 290.917969 C 300.851562 290.917969 295.609375 291.160156 292.1875 294.046875 L 288.691406 297 L 293.40625 298.945312 C 299.996094 301.660156 339.070312 302.226562 370.6875 302.226562 C 400.007812 302.226562 435.429688 301.613281 443.929688 300.269531 L 446.078125 299.929688 L 447.054688 298.546875 C 451.140625 292.804688 449.472656 282.480469 447.863281 272.496094 C 446.972656 267.976562 446.476562 263.425781 446.375 258.863281 L 451.1875 226.789062 C 451.667969 225.929688 452.242188 225.097656 452.90625 224.300781 C 455.21875 221.394531 456.777344 218.433594 450.617188 214.320312 Z M 450.617188 214.320312 "
				/>
			</g>
		</g>
	</svg>
);
