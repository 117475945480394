import React, { FC, Fragment } from 'react';

import { Checkbox, Stack } from '@icasdigital/icas.core.reactcomponents';

import { SubjectsForSubmission, UniInfo } from '../types/UniInfo';

import './ComponentStyle.css';

type SubjectSelectionProps = {
	handleSelect: (id: string, checked: boolean) => void;
	uniInfo?: UniInfo;
	selectedSubjects: SubjectsForSubmission[];
};

export const SubjectSelection: FC<SubjectSelectionProps> = ({
	handleSelect,
	uniInfo,
	selectedSubjects,
}) => {
	if (!uniInfo || !uniInfo.subjects) {
		return null;
	}
	const subjectOptions = uniInfo?.subjects.map(subject => {
		const checked: boolean = selectedSubjects.some(
			selectedSubjects => selectedSubjects.subject === subject.name
		);
		return (
			<Fragment key={subject.name}>
				<Checkbox
					handleChange={handleSelect}
					id={subject.name}
					checked={checked}
					label={subject.name}
					style={{ fontSize: '0.85em' }}
				/>
			</Fragment>
		);
	});

	return (
		<Stack
			as="div"
			gap={2}
			role="group"
			aria-labelledby="subject-header"
			className="component-container subject-select"
		>
			<h3 id="subject-header" style={{ marginBottom: 0 }}>
				Please select which subjects you would like to apply for exemptions:
			</h3>
			<Stack as="div" gap={2} className="subject-options">
				{subjectOptions}
			</Stack>
		</Stack>
	);
};
