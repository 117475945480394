// These are used for the current 'special case' unis where the options are seperated by degrees

import { LeicesterValue, RguValue } from './universities';

export type DegreeOption = {
	name: string;
	value: string;
};

const LeicesterDegreeOptions: DegreeOption[] = [
	{
		name: 'BSc (Hons) Accounting, BSc (Hons) Accounting and Finance',
		value: 'BSc (Hons) Accounting, BSc (Hons) Accounting and Finance',
	},
	{
		name: 'BSc (Hons) Economics & Accounting',
		value: 'BSc (Hons) Economics & Accounting',
	},
];

const RguDegreeOptions: DegreeOption[] = [
	{
		name: 'BA (Hons) Accounting and Finance',
		value: 'BA (Hons) Accounting and Finance',
	},
	{
		name: 'BA (Hons) Accounting with Data Science',
		value: 'BA (Hons) Accounting with Data Science',
	},
	{
		name: 'GA in BA (Hons) Accounting',
		value: 'GA in BA (Hons) Accounting',
	},
];

type DegreeOptionsType = {
	[unicode: number]: DegreeOption[];
};

export const DegreeOptions: DegreeOptionsType = {
	[LeicesterValue]: LeicesterDegreeOptions,
	[RguValue]: RguDegreeOptions,
};

export const returnDegreeOptions = (uniCode: number) =>
	DegreeOptions[uniCode] ?? [];
