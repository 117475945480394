import React from 'react';
import { Outlet } from 'react-router-dom';

import { AuthProvider, Layout } from '@icasdigital/icas.core.reactcomponents';

import './custom.css';

export const App = (): JSX.Element => (
	<AuthProvider>
		<Layout>
			<div id="app-container">
				<div id="title-container">
					<h1 id="app-title">Student Exemptions</h1>
				</div>
				<Outlet />
			</div>
		</Layout>
	</AuthProvider>
);
