import React, { FC } from 'react';
import { useLottie } from 'lottie-react';

import successJSON from '../assets/success.json';

import { ErrorForm } from './ErrorForm';

import './ComponentStyle.css';

type SubmissionPageProps = {
	success: boolean;
	errorMessage?: string | null;
};

export const PostSubmissionPage: FC<SubmissionPageProps> = ({
	success,
	errorMessage,
}) => {
	const lottieOptions = {
		animationData: successJSON,
		loop: false,
		autoplay: true,
	};

	const lottieStyle = {
		height: '15em',
		margin: 'auto',
	};

	const { View } = useLottie(lottieOptions, lottieStyle);
	if (success) {
		return (
			<div className="component-container">
				{View}
				<h2 style={{ alignSelf: 'center', color: '#585858' }}>
					Thank you - your application has been submitted to ICAS
				</h2>
			</div>
		);
	}
	return <ErrorForm handleClick={() => {}} pageType="page" />;
};
