export type university = {
	name: string;
	value: number;
};

export const RguValue = 605340165;
export const LeicesterValue = 605340077;

export const universities: university[] = [
	{ name: 'University of Aberdeen', value: 605340127 },
	{ name: 'University of Bolton', value: 605340117 },
	{ name: 'University of Dundee', value: 605340099 },
	{ name: 'University of Edinburgh', value: 605340096 },
	{ name: 'University of Exeter', value: 605340094 },
	{ name: 'Edinburgh Napier University', value: 605340257 },
	{ name: 'University of Glasgow', value: 605340305 },
	{ name: 'Glasgow Caledonian University', value: 605340246 },
	{ name: 'Heriot-Watt University', value: 605340237 },
	{ name: 'Lancaster University', value: 605340212 },
	{ name: 'University Of Leicester', value: LeicesterValue },
	{ name: 'City University of London', value: 605340271 },
	{ name: 'Robert Gordon University', value: RguValue },
	{ name: 'University of Stirling', value: 605340042 },
	{ name: 'University of Strathclyde', value: 605340040 },
	{ name: 'University of Surrey', value: 605340037 },
	{ name: 'University of West Scotland', value: 605340030 },
	{ name: 'University of Liverpool', value: 605340074 },
	{ name: 'University of Leeds', value: 605340078 },
	{ name: 'University of Bristol', value: 605340113 },
	{ name: 'University of Birmingham', value: 605340118 },
	{ name: 'University of Abertay', value: 605340303 },
	{ name: 'University of Warwick', value: 605340026 },
	{ name: 'Association of Accounting Technicians', value: 605340299 },
	{ name: 'Nottingham Trent University', value: 605340181 },
	{ name: 'Durham University', value: 605340262 },
	{ name: 'Middlesex University London', value: 605340191 },
	{ name: 'Queen’s University Belfast', value: 605340167 },
	{ name: 'University of Bath', value: 605340121 },
	{ name: 'University of Portsmouth', value: 605340053 },
	{ name: 'University of Sheffield', value: 605340048 },
];

export const returnUniName = (unicode?: number) => {
	if (!unicode) {
		return;
	}
	return universities.find(u => u.value === unicode)?.name;
};
