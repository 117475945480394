import { useCallback, useEffect, useRef, useState } from 'react';

import { UniSubject } from '../types/UniInfo';

type UseRulesProps = {
	subject: UniSubject | undefined;
};

export type HandleFunction = (id: string, checked: boolean) => void;

type UseRulesReturn = {
	updateRules: (rules: ConfirmedRule[]) => void;
	isLoaded: boolean;
	handleCheckbox: HandleFunction;
	rules: ConfirmedRule[];
};

type UseRulesHook = (props: UseRulesProps) => UseRulesReturn;

type ConfirmedRule = {
	name: string;
	confirmed: boolean;
};

export const useRules: UseRulesHook = ({ subject }) => {
	const [rulesToConfirm, setRulesToConfirm] = useState<ConfirmedRule[]>([]);
	const rulesRef = useRef(rulesToConfirm);
	const [isLoaded, setIsLoaded] = useState<boolean>(false);

	const updateRefAndState = useCallback((newState: ConfirmedRule[]) => {
		rulesRef.current = [...newState];
		setRulesToConfirm([...newState]);
	}, []);

	useEffect(() => {
		if (!isLoaded && subject) {
			const confirmedRulesToAdd: ConfirmedRule[] = [];
			subject.rules.forEach((rule, index) => {
				if (rule.type === 'or') {
					rule.modules.forEach((m, index) => {
						const id = `${subject.name}-${rule.type}-${index}`;
						const ruleToAdd: ConfirmedRule = {
							name: id,
							confirmed: false,
						};
						confirmedRulesToAdd.push(ruleToAdd);
					});
				} else {
					const id = `${subject.name}-${rule.type}-${index}`;
					const ruleToAdd: ConfirmedRule = {
						name: id,
						confirmed: false,
					};
					confirmedRulesToAdd.push(ruleToAdd);
				}
				updateRefAndState([...rulesToConfirm, ...confirmedRulesToAdd]);
			});
			setIsLoaded(true);
		}
	}, [isLoaded, rulesToConfirm, subject, updateRefAndState]);

	const handleCheckbox: HandleFunction = useCallback(
		(id, checked) => {
			const currentConfirmedRules = [...rulesRef.current];
			const ruleId = id.replace('-checkbox', '');
			currentConfirmedRules.forEach(rule => {
				if (rule.name === ruleId) {
					rule.confirmed = checked;
				}
				return;
			});
			updateRefAndState(currentConfirmedRules);
		},
		[updateRefAndState]
	);

	return {
		updateRules: updateRefAndState,
		isLoaded,
		handleCheckbox,
		rules: rulesToConfirm,
	};
};
