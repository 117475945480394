export const renderGBP = (price: number) => {
	const formatter = new Intl.NumberFormat('en-GB', {
		style: 'currency',
		currency: 'GBP',
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	});
	return formatter.format(price);
};

export const renderDateString = (dateString: string) => {
	const date = new Date(dateString);
	return date.toLocaleDateString('en-GB');
};

export const formatDegreeString = (degrees?: string[]) => {
	let degreeString = '';
	if (degrees && degrees.length) {
		degrees.forEach((degree, index) => {
			if (index + 1 === degrees.length) {
				return (degreeString += `${degree}`);
			}
			degreeString += `${degree}, `;
		});
	}
	return degreeString;
};

export const RuleLabels = {
	all: 'Please confirm you have passed all of the following modules:',
	one: 'Please confirm you have passed the following module:',
	or: 'Please confirm you have passed one of the following modules :',
	'or both': 'or both:',
};

export const renderRuleLabels = (
	rule: keyof typeof RuleLabels,
	length: number
) => {
	if (rule === 'all') {
		return length && length > 1 ? RuleLabels[rule] : RuleLabels['one'];
	}
	return RuleLabels[rule];
};
