import React, { FC, memo, useEffect, useRef } from 'react';

import { useRules } from '../hooks/useRules';
import { OrAllRulesType, UniSubject } from '../types/UniInfo';

import { RuleCheckbox, Rules } from './Rules';

import './ComponentStyle.css';

type HandleConfirmedFunction = (name: string, isConfirmed: boolean) => void;

type SubjectModuleRulesProps = {
	handleConfirmed: HandleConfirmedFunction;
	subject: UniSubject | undefined;
};

const SubjectsModuleRules: FC<SubjectModuleRulesProps> = ({
	subject,
	handleConfirmed,
}) => {
	const confirmedToContainer = useRef(false);

	const { isLoaded, handleCheckbox, rules } = useRules({ subject });

	const allConfirmed = rules.every(rule => rule.confirmed);

	const isChecked = (id: string) => {
		const foundRule = rules.find(r => r.name === id);
		if (foundRule) {
			return foundRule.confirmed;
		}
		return false;
	};

	useEffect(() => {
		if (!isLoaded || !subject) {
			return;
		}
		if (!confirmedToContainer.current && allConfirmed) {
			handleConfirmed(subject?.name, allConfirmed);
			confirmedToContainer.current = true;
			return;
		}
		if (confirmedToContainer.current === true && !allConfirmed) {
			handleConfirmed(subject?.name, allConfirmed);
			confirmedToContainer.current = false;
		}
	}, [handleConfirmed, isLoaded, subject, confirmedToContainer, allConfirmed]);

	if (!isLoaded) {
		return null;
	}
	const subjectName = subject?.name || '';
	return (
		<div className="component-container" key={`subject${subjectName}`}>
			<h3
				style={{
					marginBottom: 0,
				}}
			>
				{subject?.name}
			</h3>
			<ul>
				{subject?.rules.map((rule, index) => {
					if (rule.type === 'all') {
						const id = `${subjectName}-${rule.type}-${index}`;
						const checked = isChecked(id);
						return (
							<Rules
								subject={subjectName}
								key={id}
								spanName={id}
								modulesArray={rule.modules}
								type={rule.type}
							>
								<RuleCheckbox
									id={id}
									handleCheckbox={handleCheckbox}
									checked={checked}
								/>
							</Rules>
						);
					}
					if (rule.type === 'or') {
						return rule.modules.map((twinModuleArray: any, index: number) => {
							const id = `${subjectName}-${rule.type}-${index}`;
							const checked = isChecked(id);
							return (
								<Rules
									type={rule.type}
									modulesArray={twinModuleArray}
									subject={subjectName}
									key={id}
									spanName={id}
								>
									<RuleCheckbox
										id={id}
										handleCheckbox={handleCheckbox}
										checked={checked}
									/>
								</Rules>
							);
						});
					}
					if (rule.type === 'one of') {
						const id = `${subjectName}-${rule.type}-${index}`;
						const checked = isChecked(id);
						return rule.rules.map((rules: OrAllRulesType, index: number) => {
							const lastRule = index === rule.rules.length - 1;
							if (lastRule) {
								return (
									<Rules
										key={id}
										subject={subjectName}
										modulesArray={rules.modules}
										type={rules.type}
										spanName={id}
									>
										<RuleCheckbox
											id={id}
											handleCheckbox={handleCheckbox}
											checked={checked}
										/>
									</Rules>
								);
							}
							return (
								<Rules
									key={id}
									subject={subjectName}
									modulesArray={rules.modules}
									type={rules.type}
									spanName={id}
								/>
							);
						});
					}
					return null;
				})}
			</ul>
		</div>
	);
};

export default memo(SubjectsModuleRules);
