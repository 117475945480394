import React, { FC } from 'react';
import { useLottie } from 'lottie-react';

import { Button } from '@icasdigital/icas.core.reactcomponents';

import errorData from '../assets/error.json';

import { Arrow } from './Submission';

import './ComponentStyle.css';
import './ErrorFormStyle.css';

type ErrorFormProps = {
	error?: string;
	pageType: 'page' | 'form';
	handleClick: () => void;
};

export const ErrorForm: FC<ErrorFormProps> = ({
	error,
	pageType = 'page',
	handleClick,
}) => {
	const lottieStyle = {
		height: '15em',
		margin: 'auto',
	};

	const errorOptions = {
		loop: false,
		autoplay: true,
		animationData: errorData,
		style: lottieStyle,
	};

	const { View } = useLottie(errorOptions);

	return (
		<div>
			<div className="component-container error-container">
				<div id="lottie-container">{View}</div>
				<h2 className="heading">Error</h2>
				<h3 className="heading">
					{error ||
						// eslint-disable-next-line quotes
						"We're sorry, something has gone wrong. Please contact ICAS"}
				</h3>
				{pageType === 'page' && (
					<div style={{ marginTop: '2em', marginLeft: 'auto' }}>
						<Button
							id="error"
							text="Back"
							onClick={handleClick}
							style={{ width: '8em' }}
							icon={<Arrow />}
						/>
					</div>
				)}
			</div>
			{pageType !== 'page' && (
				<div style={{ marginTop: 'auto', marginLeft: 'auto' }}>
					<Button id="error" text="Back" onClick={handleClick} />
				</div>
			)}
		</div>
	);
};
