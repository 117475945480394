import React, { FC } from 'react';

import { Button, VisuallyHidden } from '@icasdigital/icas.core.reactcomponents';

import { UniInfo } from '../types/UniInfo';
import { formatDegreeString } from '../utils/Formatting';
import { returnUniName, universities } from '../utils/universities';

import { MortarImage } from './MortarImage';
import { BottomTab, TopTab } from './TabImage';

import './ComponentStyle.css';

type StudentDetailsProps = {
	uniInfo?: UniInfo;
	accreditedUni: boolean;
	isNotICAEW: boolean;
	firmName: string;
	handleICAEW: () => void;
	needsDegreeInfo: boolean;
	uniCode?: number;
};

const unaccreditedLinkClick = () => {
	window.location.href =
		'https://icas.microsoftcrmportals.com/student-exemptions';
	return;
};

export const StudentDetails: FC<StudentDetailsProps> = ({
	uniInfo,
	accreditedUni,
	isNotICAEW,
	firmName,
	handleICAEW,
	uniCode,
}) => {
	const degreeString = formatDegreeString(uniInfo?.degrees);

	if (accreditedUni) {
		const uniName = returnUniName(uniCode);
		return (
			<>
				<div className="component-container student-details-container">
					<VisuallyHidden as="h2" id="subject-header">
						Student details:
					</VisuallyHidden>
					<div className="student-info">
						<TopTab />
						<BottomTab />
						<p>
							<span className="heading">University:</span> {uniName}
						</p>
						<p>
							<span className="heading"> Student Training Firm:</span>{' '}
							{firmName}
						</p>
					</div>
					<p className="details-check">
						Please check your university and student training firm details. If
						your university details are incorrect please fill in the{' '}
						<a href="https://icas.microsoftcrmportals.com/forms/student-details">
							student details form
						</a>{' '}
						or if your student training firm is incorrect please contact ICAS
						first as this could affect your exemption application.
					</p>
				</div>
				{uniInfo && (
					<>
						<div className="student-degree-container component-container">
							<div className="degree-text">
								<h3>{uniName} degree requirements:</h3>
								<div>
									<p className="degree-requirements">
										<span className="heading">Degree requirements:</span>{' '}
										{degreeString}
									</p>
								</div>
							</div>
							<div className="degree-image">
								<MortarImage />
							</div>
						</div>
					</>
				)}
			</>
		);
	}
	if (!isNotICAEW) {
		return (
			<>
				<div className="component-container student-details-container">
					<VisuallyHidden as="h2" id="subject-header">
						Student details:
					</VisuallyHidden>
					<div className="student-info">
						<TopTab />
						<BottomTab />
						<p>
							<span className="heading">Professional Body:</span> {'ICAEW'}
						</p>
						<p>
							<span className="heading"> Student Training Firm:</span>{' '}
							{firmName}
						</p>
					</div>
					<p className="details-check">
						Please check your student training form details. If your student
						training firm is incorrect please contact ICAS first as this could
						affect your exemption application.
					</p>
				</div>
			</>
		);
	}
	return (
		<div className="component-container">
			<div>
				<VisuallyHidden as="h2" id="subject-header">
					Please find the link for either ICAEW applications or non-accredited
					universities below. If you believe your university should have an
					accredited link, please contact ICAS
				</VisuallyHidden>
				<p>Please find the link for ICAEW below</p>
				<Button
					text={'ICAEW Exemption Form'}
					onClick={handleICAEW}
					id={'icaew form'}
					style={{ width: '15em' }}
				/>
			</div>
			<div>
				<p>Please find the link for non-accredited universities below</p>
				<Button
					text={'Exemption Form'}
					id="non-accredited button"
					onClick={unaccreditedLinkClick}
					style={{ width: '15em' }}
				/>
			</div>
		</div>
	);
};
