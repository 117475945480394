import React, { FC } from 'react';

import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	Button,
	FormError,
	LiveRegion,
} from '@icasdigital/icas.core.reactcomponents';

import { SubjectsForSubmission } from '../types/UniInfo';

import './ComponentStyle.css';

type SubmissionProps = {
	subjects: SubjectsForSubmission[];
	invalid: boolean;
};

export const Arrow = () => <FontAwesomeIcon icon={faArrowRight} />;

export const Submission: FC<SubmissionProps> = ({ subjects, invalid }) => {
	const invalidMessage = invalid
		? 'You must confirm all requirements for subjects selected and upload your transcript before submitting'
		: '';

	const padding = invalid ? '2em' : '0.8em';

	if (!subjects.length) {
		return null;
	}
	return (
		<div className="component-container" style={{ paddingTop: padding }}>
			<LiveRegion role="alert" id="form-error">
				{invalid && (
					<FormError
						id="invalid-submit"
						title="Error"
						message={invalidMessage}
					/>
				)}
			</LiveRegion>
			<Button
				id="submit"
				text="Submit"
				disabled={invalid}
				type="submit"
				style={{ marginTop: '1em', width: '9em', alignSelf: 'flex-end' }}
				icon={<Arrow />}
			/>
		</div>
	);
};
