import ReactDOM from 'react-dom/client';
import {
	createBrowserRouter,
	createRoutesFromElements,
	Route,
	RouterProvider,
} from 'react-router-dom';

import { PrivateRoute } from '@icasdigital/icas.core.reactcomponents';

import { ExemptionsContainer } from './containers/ExemptionsContainer';
import { App } from './App';
import registerServiceWorker from './registerServiceWorker';

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

const loginPath =
	'/authentication/login?returnUrl=' +
	encodeURIComponent(window.location.toString());

const router = createBrowserRouter(
	createRoutesFromElements(
		<Route element={<App />}>
			<Route element={<PrivateRoute loginPath={loginPath} />}>
				<Route path="/" element={<ExemptionsContainer />} />
			</Route>
		</Route>
	)
);

root.render(<RouterProvider router={router} />);

registerServiceWorker();
