import { LoadingStatus } from '@icasdigital/icas.core.reactcomponents';

export const isErrorCode = (responseCode: number) =>
	responseCode === 400 ||
	responseCode === 500 ||
	responseCode === 403 ||
	responseCode === 404;

export const isUnauthCode = (responseCode: number) => responseCode === 401;

export const pageAndUserLoaded = (
	userId: string | undefined,
	loadingStatus: LoadingStatus
): boolean =>
	loadingStatus !== LoadingStatus.IsLoading &&
	loadingStatus !== LoadingStatus.EndedWithError &&
	userId !== null;
